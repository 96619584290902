import React from "react"

interface Props {
  size: "small" | "large"
  readonly value?: string
}

const Searchbox = ({size, value}: Props) => {
  return (
    <div className={`sitesearch ${size === "small" ? "mainsearch" : "large"}`}>
      <form method="get" action="/search">
        <input
          className="searchbox"
          placeholder="Zoeken"
          type="text"
          defaultValue={value || ""}
          name="q"
          tabIndex={1}
          id="placeholder"
        />
        <button className="icon-search" type="submit" value="" tabIndex={2} />
      </form>
    </div>
  )
}

export default Searchbox
