import * as React from "react"
import {Link, HeadFC} from "gatsby"
import Searchbox from "../components/searchbox"
import Background from "../components/layout/background"
import Blocks from "../components/layout/blocks"
import Lines from "../components/layout/lines"

import "../styles/global.scss"
import {SEO} from "../components/seo"

const NotFoundPage = (): JSX.Element => {
  const [searchQuery, setSearchQuery] = React.useState<string>()

  React.useEffect(() => {
    const urlPath: string | undefined =
      typeof window !== "undefined"
        ? window.location.pathname.split("/").pop()
        : ""
    setSearchQuery(urlPath)
  }, [])

  return (
    <>
      <Background />
      <Blocks />
      <Lines />
      <div className="logo">
        <a href="/">
          <img src="/images/logo/pgs.gif" alt="PGS" />
        </a>
      </div>
      <div className="errorpage">
        <div className="error-introduction">
          <h1>404 - Pagina niet gevonden</h1>
          <Link to="/">&lsaquo; Terug naar de homepage</Link>
        </div>
        <div>
          <p>
            De opgevraagde pagina kan niet gevonden worden. Controleer the URL
            of gebruik het onderstaande formulier om de pagina te zoeken.
          </p>
          <Searchbox size="large" value={searchQuery} />
        </div>
      </div>
    </>
  )
}

export default NotFoundPage

export const Head: HeadFC = () => <SEO title="Pagina niet gevonden" />
