import React from "react"

const Lines = () => {
  return (
    <>
      <div className="lines">
        <div className="lines-overlay"></div>
      </div>
    </>
  )
}

export default Lines
